import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CreateEditProjectTask from "../components/CreateEditProjectTask";
import CreateEditTaskProjectControl from "../components/CreateEditTaskProjectControl";
import ErrorIcon from '@mui/icons-material/Error'; //High priority
import WarningIcon from '@mui/icons-material/Warning'; //Medium priority
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'; //Low priority
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useLocation } from 'react-router-dom';
//import IconButton from '@mui/material/IconButton';
//import Badge from '@mui/material/Badge';
//import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
//import Avatar from '@mui/material/Avatar';
import { Select, MenuItem } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import GanttComponent from '../dhtmlx/GanttDemo'
import '../dhtmlx/styles.css'
import { v4 as uuidv4 } from 'uuid';
import TaskCalendar from '../components/TaskCalendar';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import CableIcon from '@mui/icons-material/Cable';
import Tooltip from '@mui/material/Tooltip';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { LicenseManager } from 'ag-grid-enterprise'
import CreateEditTaskProjectRisk from '../components/CreateEditTaskProjectRisk';
import CreateEditTaskProjectAuditTest from '../components/CreateEditTaskAuditTest';
import '../css/WBSStudio.css'
import Loader from '../components/Loader';

LicenseManager.setLicenseKey("[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-059381}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{14 June 2024}____[v3]_[0102]_MTcxODMxOTYwMDAwMA==d5231535e6cc422dcca3530626051086")
/* 
********START all endpoints called
*/

export async function loadData(location){
    var url = `${process.env.REACT_APP_API_URL}/tasks/wbs/${location?.pathname?.split("/")[4]}`;
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => {
        return error.message;
    })
    return response;
}

export async function getWbsData (location) {
    var url = `${process.env.REACT_APP_API_URL}/wbs/${location?.pathname?.split("/")[4]}`;
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function getProjectUsers (location)  {
    var url = `${process.env.REACT_APP_API_URL}/projects/${location?.pathname?.split("/")[2]}/users?offset=0&limit=10&sort_by=email`;
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

export async function loadProjectData (location) {
    var projectId = location?.pathname?.split("/")[2]
    var url = `${process.env.REACT_APP_API_URL}/projects/${projectId}`;
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })
    return response;
}

/* 
********END all endpoints called
*/

/* START process data function for ag grid to format parent and child tasks */
export function processData (data) {
    const flattenedData = [];

    const flattenRowRecursive = (row, parentPath, order) => {
        row.childIndex = order;
        var dataPath = [...parentPath, row.id];
        if (row.parent_task_id !== 0 && row.children) {
            dataPath = [...parentPath, row.parent_task_id, row.id];
        }
        dataPath = dataPath.filter(item => item != null)
        flattenedData.push({ ...row, dataPath });
        if (row.children) {
            var countchildren = 0;
            dataPath = dataPath.filter(item => item != null)
            row.children.sort((a, b) => a?.child_task_order < b?.child_task_order)
            row.children.forEach((child) => {
                countchildren++
                flattenRowRecursive(child, dataPath, countchildren)
            });
        }
    };
    if (data != null) {
        data.forEach((row) => flattenRowRecursive(row, [], []));
    }
    return flattenedData;
};
/* END process data function for ag grid to format parent and child tasks */

const WBSStudio = () => {
    const location = useLocation();
    let newCount = 0;
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    //const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const gridRef = useRef(); // Optional - for accessing Grid's API
    const [rowData, setRowData] = useState([]); // Set rowData to Array of Objects, one Object per Row
    const [projectData, setProjectData] = useState([]);
    const [openCreateProjectTaskDialog, setOpenCreateProjectTaskDialog] = useState(null);
    const wbsId = location?.pathname?.split("/")[4];
    const [openCreateProjectControlDialog, setOpenCreateProjectControlDialog] = useState(null);
    const [openCreateRiskDialog, setOpenCreateRiskDialog] = useState(null);
    const [openCreateAuditTestDialog, setOpenCreateAuditTestDialog] = useState(null);
    const [taskData, setTaskData] = useState(null);
    const [wbsView, setWbsView] = useState(0);
    const [wbsData, setWBSData] = useState([]);
    const [projectUsers, setProjectUsers] = useState([])

    // Group columns
    const groupHeaderHeight = 80;
    // Label columns
    const headerHeight = 80;

    // useEffect(() => {

    // }, [projectUsers])

    // useEffect(() => {
    //     console.log('rowData', rowData)
    // }, [rowData])

    // useEffect(() => {
    //     //console.log('projData', projectData)
    // }, [projectData])

    useEffect(() => {
        console.log('taskData', taskData)
    }, [taskData])

    const [isLoading, setIsLoading] = useState();

    const wbsViewOptions = [
        { value: 0, label: "Project Timeline View" },
        { value: 1, label: "Gantt Chart View" },
        { value: 2, label: "Calendar View" },
    ]

    const { control } = useForm({
        defaultValues: {
            wbsViewSelect: {},
        }
    });

    const handleWBSViewSelect = (event) => {
        setWbsView(event.target.value)
    }

    const handleClose = () => {
        setOpenCreateProjectTaskDialog(false);
        setOpenCreateProjectControlDialog(false);
        setOpenCreateRiskDialog(false);
        setOpenCreateAuditTestDialog(false);

    }

    const isOpen = () => {
        getData()
        gridRef.current.api.redrawRows()
        handleClose();
    }

    const addControlData = (data) => {
        if (data?.project_controls != null && data?.project_controls?.length > taskData?.project_controls?.length) {
            //need to add controls to taskData
            let objToAdd = data.project_controls[taskData.project_controls.length]
            let formatObj = {
                Control: objToAdd.control,
                ProjectControl: {
                    id: objToAdd.id
                },
            }
            taskData.project_controls.push(formatObj)
        }
        if (data?.audit_tests != null && data?.audit_tests.length > taskData?.audit_tests.length) {
            //need to add audit tests to taskData
            let objToAdd = data.audit_tests[taskData.audit_tests.length]
            let formatObj = {
                AuditTest: objToAdd,
            }
            taskData.audit_tests.push(formatObj)
            setTaskData(taskData)
        }
        if (data?.risks != null && data?.risks.length > taskData?.risks.length) {
            //need to add risks to taskData
            let objToAdd = data.risks[taskData.risks.length]
            let formatObj = {
                Risk: objToAdd,
            }
            taskData.risks.push(formatObj)
        }
    }

    const undoRedoCellEditing = true;
    const undoRedoCellEditingLimit = 20;

    //const [parentTask, setParentTask] = useState([]);


    const ProjectControlsRenderer = (props) => {
        const controls = props?.data?.project_controls?.map(
            (control) => {
                return (
                    <li>
                        <Link href={`/projects/${projectData?.id}/controls/${control?.TaskProjectControl?.project_control_id}`}>{control?.Control?.name}</Link>
                    </li>
                )
            }
        )
        return (
            <ul>
                {controls}
            </ul>
        )
    };

    const PriorityRenderer = (props) => {
        const priority = props?.data?.priority
        if (props?.data) {
            return (
                <>
                    {priority === "High" && <><ErrorIcon style={{ color: '#FF0033' }} />High</>}
                    {priority === "Medium" && <><WarningIcon style={{ color: '#FF9900' }} />Medium</>}
                    {priority === "Low" && <><ArrowDownwardIcon style={{ color: '#0099FF' }} />Low</>}
                </>
            )
        } else {
            return (<p>N/A</p>)
        }
    };

    function createNewRowData(props) {
        const newData = {
            id: "new" + newCount,
            name: "New Task",
            dataPath: [
                props.data.dataPath[0], newCount
            ],
            child_task_order: props.data.children.length,
            parent_id: props.data.id,
        };
        newCount++;
        return newData;
    }

    const createTask = useCallback(async (task) => {
        var url = `${process.env.REACT_APP_API_URL}/tasks/task`;
        var parentArr = []
        parentArr.push(task.parent_id)
        var body = JSON.stringify(
            {
                "title": task.name,
                "name": task.name,
                "wbs_id": wbsId,
                "user_id": localStorage.getItem("userid"),
                "child_task_order": task.child_task_order,
                "parents": parentArr
            }

        )
        const response = axios(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
            data: body
        }).then(result => {
            return result;
        }).catch(error => { return error.message; })
        return response;

    }, [wbsId])

    const addNewRow = async (props) => {
        const newItem = createNewRowData(props)
        await createTask(newItem)
        const jsonData = await loadData(location);
        let copyRowData = jsonData.data
        setRowData(processData(copyRowData));
        //etRowData(copyRowData)
        gridRef.current.api.redrawRows()
    }

    const ChildMessageRenderer = (props) => {
        const invokeParentMethod = () => {
            props.context.methodFromParent(
                `${props.data.id}`
            );
        };

        return (
            <>
                {props?.node?.parent?.group ?
                    <span>

                        <Button
                            style={{ height: 20, lineHeight: 0.5 }}
                            onClick={() => { addNewRow(props); handleClose(); }}
                            onClickCapture={invokeParentMethod}
                            className="btn btn-add"
                            startIcon={<AddCircleIcon />}
                        />

                        <Button startIcon={<DeleteIcon />} onClick={() => { deleteTask(props.node.data); }} />
                    </span> : <span>
                        <Button startIcon={<DeleteIcon />} onClick={() => { deleteTask(props.node.data); }} />
                    </span>}

            </>
        );
    };

    // Each Column Definition results in one Column.
    const columnDefs = [
        { field: 'parent_task_id', hide: true, filter: false },
        { field: 'id', hide: true, filter: false },
        { field: 'child_task_order', hide: true },
        {
            field: 'name',
            filter: true,
            editable: true,
            headerName: `Task`,
            rowDrag: true,
            //checkboxSelection: true,
            cellDataType: 'text',
            headerTooltip: "The task's name",
        },
        /*{ field: 'description', filter: false, editable: true },*/
        {
            field: 'assigned',
            filter: true,
            headerName: `Assigned to`,
            //cellRenderer: TaskAssignedToRenderer,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: projectUsers,
                valueListGap: 0
            },
            editable: true,
            headerTooltip: "A user who has been added to the project or the logged in user",
        },
        {
            field: 'project_controls',
            filter: false,
            headerName: `Controls`,
            cellRenderer: ProjectControlsRenderer,
            wrapText: true,
            hide: true,
        },
        /*{
            field: 'risks',
            filter: false,
            headerName: `Risks`,
            cellRenderer: RisksRenderer,
            wrapText: true,
            hide: true
        },
        {
            field: 'audit_tests',
            filter: false,
            headerName: `Audit Tests`,
            cellRenderer: AuditTestsRenderer,
            wrapText: true,
            hide: true
        },*/
        {
            field: 'status',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Not Started', 'In Progress', 'Completed'],
                valueListGap: 0
            },
            filter: true,
            editable: true,
            headerTooltip: "The current working status of the task",
        },
        {
            field: 'priority',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Low', 'Medium', 'High'],
                valueListGap: 0
            },
            filter: true,
            editable: true,
            cellRenderer: PriorityRenderer,
            headerTooltip: "The priority of the task",
        },
        /*{
            field: 'task_history',
            filter: false,
            editable: false,
            cellRenderer: TaskHistoryRenderer,
            headerName: 'Comments'
        },*/
        { field: 'due_date', editable: true, headerName: `Due Date`, headerTooltip: "The task's due date", },
        { field: 'actual_start_date', editable: true, headerName: `Start Date`, headerTooltip: "The actual start date of the task", },
        { field: 'actual_end_date', editable: true, headerName: `End Date`, headerTooltip: "The actual end date of the task", },
        {
            headerName: 'Action',
            field: 'value',
            cellRenderer: ChildMessageRenderer,
            colId: 'params',
            editable: false,
            minWidth: 200,
        },
    ];

    const getData = useCallback(async () => {

        const jsonData = await loadData(location);

        if (jsonData === "Request failed with status code 404") {

            //setErrMsg(`${jsonData.status}`)
            setIsLoading(false)
        }
        else {
            setRowData(processData(jsonData.data))
            formatGanttData(jsonData.data)
            setIsLoading(false)
        }

        const jsonDataUsers = await getProjectUsers(location);

        if (jsonDataUsers === "Request failed with status code 404") {

            //setErrMsg(`${jsonData.status}`)
        }
        else {
            let emailArr = []
            jsonDataUsers.data.items.map((user) => {
                emailArr.push(user.User.email)
                return emailArr
            })
            emailArr.push(localStorage.getItem("email"))
            setProjectUsers(emailArr)
        }


    }, [location])

    const deleteTask = useCallback(async (taskData) => {
        if (taskData?.children?.length > 0) {
            alert(`A task cannot be deleted if it has children`)
            return
        }
        var url = `${process.env.REACT_APP_API_URL}/tasks/${taskData?.id}`;
        const response = axios(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
        }).then(result => {
            getData()
            return result;
        }).catch(error => { return error.message; })
        return response;
    }, [getData])

    const formatGanttData = (data) => {
        var tmpArr = []
        if (data != null) {
            for (let i = 0; i < data.length; i++) {
                var start_date = "12-04-2023"
                if (data[i].actual_start_date) {
                    start_date = data[i].actual_start_date
                }
                var end_date = "12-31-2023"
                if (data[i].actual_end_date) {
                    end_date = data[i].actual_end_date
                }
                tmpArr.push({
                    id: uuidv4(),
                    title: data[i].name,
                    description: data[i].description,
                    startDate: start_date,
                    endDate: end_date,
                    bgColor: "rgb(102,153,255)",
                    percent_complete: data[i].percent_complete,
                    dependencies: null
                })
    
                if (data[i].children) {
                    for (let p = 0; p < data[i].children.length; p++) {
                        var start_date_new = new Date("12-04-2023")
                        if (data[i].children[p].actual_start_date) {
                            start_date_new = new Date(data[i].children[p].actual_start_date)
                        }
                        var end_date_new = new Date("12-31-2023")
                        if (data[i].children[p].actual_end_date) {
                            end_date_new = new Date(data[i].children[p].actual_end_date)
                        }
                        let childObj = {
                            id: uuidv4(),
                            title: data[i].children[p].name,
                            description: data[i].children[p].description,
                            startDate: start_date_new,
                            endDate: end_date_new,
                            bgColor: "rgb(153,204,255)",
                            percent_complete: data[i].children[p].percent_complete,
                            dependencies: data[i].name
                        }
                        tmpArr.push(childObj)
                    }
    
                }
    
            }
        }

        //setTasks(tmpArr)
    }

    const onGridReady = useCallback(async (params) => {
        await getData()
    }, [getData]);

    const convertToSendDate = (date) => {
        return `${new Date(date).toISOString().split("T")[0]}`;
    };

    const updateTask = useCallback(async (task) => {
        //console.log(task)
        //call data endpoint for data type to set rows and columns
        var url = `${process.env.REACT_APP_API_URL}/tasks/${task.id}`;
        var parent = task.dataPath[0]
        var parentArr = []
        if (parent !== task.id && parent !== 0) {
            parentArr.push(parent)
        }
        var childtaskorder = task.childIndex
        if (task.childIndex.length === 0) {
            childtaskorder = 0;
        }

        var body = JSON.stringify(
            {
                "title": task.name,
                "name": task.name,
                "description": task.description,
                //"fedrisk_object_type": "string",
                //"fedrisk_object_id": 0,
                "status": task.status,
                "priority": task.priority,
                "comments": "Updated in WBS",
                "due_date": task.due_date,
                "actual_start_date": convertToSendDate(task.actual_start_date),
                "actual_end_date": convertToSendDate(task.actual_end_date),
                "duration": task.duration,
                "percent_complete": task.percent_complete,
                "milestone": task.milestone,
                "assigned_to": task.assigned,
                "estimated_loe": task.estimated_loe,
                "actual_loe": task.actual_loe,
                "estimated_cost": task.estimated_cost,
                "actual_cost": task.actual_cost,
                "child_task_order": childtaskorder,
                /*"attachments": [

                ],
                "risks": [

                ],
                "audit_tests": [

                ],
                "children": [

                ],*/
                "parents": parentArr
            }

        )
        const response = axios(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.access_token}`,
            },
            data: body
        }).then(result => {
            return result;
        }).catch(error => { return error.message; })
        return response;
    }, [])

    const getProjectData = useCallback(async () => {
        const projData = await loadProjectData(location);
        const wbsDataTmp = await getWbsData(location);
        setProjectData(projData.data);
        setWBSData(wbsDataTmp.data);

    }, [location])

    // Example load data from server
    useEffect(() => {
        if (projectData?.length === 0) {
            getProjectData()
        }
    }, [projectData, getProjectData]);

    useEffect(() => {
        //console.log(wbsData)
    }, [wbsData])

    // Example of consuming Grid Event
    const cellClickedListener = useCallback(event => {
        //console.log(event)
        setTaskData(event.data);
        //setNodeData(event)
    }, []);

    const rowClickedListener = useCallback(event => {
        console.log(event.rowIndex);
    }, [])

    const cellUpdatedListener = useCallback(event => {
        updateTask(event.data)
    }, [updateTask])

    // Save WBS to db
    const saveWbs = useCallback(async (e) => {
        if (rowData) {
            // iterate through every node in the grid
            for (let i = 0; i < rowData.length; i++) {

                gridRef.current.api.forEachNode((rowNode) => {
                    rowNode = rowData[i]
                })
                updateTask(rowData[i])

            }
            //window.location.reload(false);
        }
    }, [rowData, updateTask]);

    // const getParams = () => ({
    //     processHeaderCallback(params) {
    //       return `header: ${params.api.getDisplayNameForColumn(params.column, null)}`;
    //     },
    //     processGroupHeaderCallback(params) {
    //       return `group header: ${params.api.getDisplayNameForColumnGroup(params.columnGroup, null)}`;
    //     },
    //   });

    const defaultExcelExportParams = useMemo(() => {
        return {
            allColumns: true,
        };
    }, []);

    const onRowDragEnd = useCallback((event) => {
        var movingNode = event.node;
        var overNode = event.overNode;
        var childTaskId = 0;
        var pathId = 0;
        //console.log('movingNode', movingNode)
        //console.log('overNode', overNode)
        //if the node that's being moved out of the way has children
        if (overNode.data.children && movingNode.data.children) {
            // console.log('movingNode inside overnode has children', movingNode)
            // console.log('overnode inside overnode has children', overNode)
            let prevMovingNodePath = movingNode.data.dataPath[0]
            pathId = overNode.data.dataPath[0];
            childTaskId = overNode.data.dataPath[0];
            var needToChangeParent = movingNode.data.dataPath[0] !== pathId;
            var needtoChangeChildOrder = childTaskId !== movingNode.data.childIndex
            if (needToChangeParent) {
                var movingData = movingNode.data;
                movingData.dataPath[0] = pathId;
                movingData.dataPath[1] = prevMovingNodePath;
                //overNode = movingNode
                gridRef.current.api.applyTransaction({
                    update: [movingData],
                });
                gridRef.current.api.clearFocusedCell();
            }
            //update child task order
            if (needtoChangeChildOrder) {
                var movingDataChild = movingNode.data;
                let swapIndex = movingDataChild.childIndex
                movingDataChild.childIndex = childTaskId;
                var overDataChild = overNode.data;
                overDataChild.childIndex = swapIndex
                movingNode.setData(movingDataChild)
                overNode.setData(overDataChild)
                gridRef.current.api.applyTransaction({
                    add: [movingDataChild],
                    //add: [overDataChild],
                });
            }
        } else {
            // if over a non-parent, we take the path directly
            pathId = overNode.data.dataPath[0];
            //childTaskId = overNode.data.dataPath[0];
        }
        if (movingNode.data.children && !overNode.data.children) {
            //break out moving node to be a parent of nodes it is moving between
            pathId = overNode.data.dataPath[0];
            childTaskId = movingNode.data.dataPath[0];
            // console.log('movingNode inside movingNode has children', movingNode)
            // console.log('overNode inside movingNode has children', overNode)

        } else {
            // if moving a non-parent
            // console.log('moving non parent movingNode', movingNode)
            // console.log('moving non parent overNode', overNode)
            pathId = overNode.data.dataPath[0];
            childTaskId = overNode.data.dataPath[0];
        }
        if (overNode.data.children && !movingNode.data.children) {
            //break out moving node to be a parent of nodes it is moving between
            pathId = overNode.data.dataPath[0];
            childTaskId = movingNode.data.dataPath[0];
            //console.log('movingNode inside movingNode has children', movingNode)
            //console.log('overNode inside movingNode has children', overNode)

        } else {
            // if moving a non-parent
            //console.log('moving non parent movingNode', movingNode)
            //console.log('moving non parent overNode', overNode)
            pathId = overNode.data.dataPath[0];
            childTaskId = overNode.data.dataPath[0];
        }
        //console.log('pathId', pathId)
        //console.log('childTaskId', childTaskId)
        var needToChangeParentNew = movingNode.data.dataPath[0] !== pathId;
        var needtoChangeChildOrderNew = childTaskId !== movingNode.data.childIndex
        if (needToChangeParentNew) {
            var movingDataNew = movingNode.data;
            movingDataNew.dataPath[0] = pathId;
            //overNode = movingNode
            gridRef.current.api.applyTransaction({
                update: [movingDataNew],
            });
            gridRef.current.api.clearFocusedCell();
        }
        //update child task order
        if (needtoChangeChildOrderNew) {
            var movingDataChildNew = movingNode.data;
            let swapIndex = movingDataChildNew.childIndex
            movingDataChildNew.childIndex = childTaskId;
            var overDataChildNew = overNode.data;
            overDataChildNew.childIndex = swapIndex
            movingNode.setData(movingDataChildNew)
            overNode.setData(overDataChildNew)
            gridRef.current.api.applyTransaction({
                add: [movingDataChildNew],
                //add: [overDataChildNew],
            });
        }
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            /*minWidth: 100,*/
            resizable: true,
            filter: true,
            floatingFilter: true,
        };
    }, []);
    const autoGroupColumnDef = useMemo(() => {
        return {
            headerName: 'Parent Task ID',
            field: 'parent_task_id',
            cellRendererParams: {
                suppressCount: true,
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                treeList: true,
                keyCreator: (params) => (params.value ? params.value.join('#') : null),
            },
            minWidth: 50,
        };
    }, []);
    const getDataPath = useMemo(() => {
        return (data) => data.dataPath;
    }, []);
    const getRowId = useMemo(() => {
        return (params) => params.data.id;
    }, []);
    const getChildIndex = useMemo(() => {
        return (data) => data.childIndex;
    }, []);
    const methodFromParent = (cell) => {
        if (cell !== null) {
            //setParentTask(cell)
        }
        setOpenCreateProjectTaskDialog(true)
    };

    const isRowSelectable = useMemo(() => {
        return (rowNode) => {
            return rowNode.data;
        };
    }, []);

    const getRowHeight = useCallback(
        (params) => {
            // if (params.data.project_controls.length > 0 || params.data.risks.length > 0 || params.data.audit_tests.length > 0) {
            //     const numcontrols = params?.data?.project_controls.length
            //     const numrisks = params?.data?.risks.length
            //     const numaudittests = params.data.audit_tests.length
            //     const numarr = [numcontrols, numrisks, numaudittests]
            //     numarr.sort((a, b) => a - b)
            //     const height = numarr[0] * 50 + 50
            //     return height
            // }
            return 50
        },
        []
    );

    useEffect(() => {
        if (wbsView !== 0) {
            setTaskData()
        }
    }, [wbsView])

    if (isLoading) {
        return (
            <Loader />
        )
    }

    return (
        <div className='WBSStudio report'>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/system-administration">
                    System Administration
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    href={`/projects/list`}
                >
                    Projects
                </Link>
                <Typography color="text.primary"><a href={`/projects/${projectData?.id}`}>{projectData?.name}</a></Typography>
            </Breadcrumbs>

            <h1>{rowData[0]?.project?.name}</h1>

            <div className="wbsStudioControls">
                <Controller
                    name="wbsViewSelect"
                    control={control}
                    render={({ field }) =>
                        <Select
                            {...field}
                            onChange={handleWBSViewSelect}
                            value={wbsView ? wbsView : 0}
                            inputProps={{ "data-testid": "wbsview-select" }}
                        >
                            {
                                wbsViewOptions?.map((w) => {
                                    return <MenuItem
                                        value={w.value}
                                        key={w.value}>
                                        {w.label}
                                    </MenuItem>
                                })
                            }
                        </Select>
                    }
                />
                <Tooltip title="Save all edits on project timeline view">
                    {wbsView === 0 && <Button onClick={saveWbs} startIcon={<SaveIcon />}>Save Tasks</Button>}
                </Tooltip>
                <Tooltip title="Add a new parent task">
                    {wbsView === 0 && <Button startIcon={<AddCircleIcon />} onClick={() => { setOpenCreateProjectTaskDialog(true); }}>Add Task</Button>}
                </Tooltip>
                {/*<Tooltip title="Export all task data">
                    {wbsView === 0 && <Button onClick={() => { exportToExcel() }}>Export</Button>}
                </Tooltip>
                <Tooltip title="Export all task data">
                    {wbsView === 0 && <Button onClick={() => { exportToCSV() }}>Export CSV</Button>}
                </Tooltip>*/}
                {taskData && <>
                    <Tooltip title="Delete selected task">
                        <Button startIcon={<DeleteIcon />} onClick={() => { deleteTask(taskData); }}>Delete</Button>
                    </Tooltip>
                    <Tooltip title="Connect an existing project control to the selected task">
                        <Button startIcon={<CableIcon />} onClick={() => { setOpenCreateProjectControlDialog(true); }}>Connect Project Control</Button>
                    </Tooltip>
                    <Tooltip title="Connect an existing risk to the selected task">
                        <Button startIcon={<CableIcon />} onClick={() => { setOpenCreateRiskDialog(true); }}>Connect Risk</Button>
                    </Tooltip>
                    <Tooltip title="Connect an existing audit test to the selected task">
                        <Button startIcon={<CableIcon />} onClick={() => { setOpenCreateAuditTestDialog(true); }}>Connect Audit Test</Button>
                    </Tooltip>
                </>}

            </div>
            {wbsView === 0 && <div style={containerStyle}>
                <div /*style={gridStyle}*/ className="ag-theme-alpine wbs-studio-grid">
                    <AgGridReact
                        ref={gridRef} // Ref for accessing Grid's API
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        onCellValueChanged={cellUpdatedListener}
                        rowSelection='single' // Options - allows click selection of rows
                        onRowClicked={rowClickedListener}
                        onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                        undoRedoCellEditing={undoRedoCellEditing}
                        undoRedoCellEditingLimit={undoRedoCellEditingLimit}
                        treeData={true}
                        animateRows={true}
                        groupDefaultExpanded={-1}
                        getDataPath={getDataPath}
                        getRowId={getRowId}
                        getRowNodeId={data => data.id}
                        getChildIndex={getChildIndex}
                        onGridReady={onGridReady}
                        context={{
                            methodFromParent,
                        }}
                        isRowSelectable={isRowSelectable}
                        //rowDragEntireRow={true}
                        //onRowDragMove={onRowDragMove}
                        //rowDragText={rowDragText}
                        onRowDragEnd={onRowDragEnd}
                        rowHeight={50}
                        getRowHeight={getRowHeight}
                        enableCharts={true}
                        groupHeaderHeight={groupHeaderHeight}
                        headerHeight={headerHeight}
                        defaultExcelExportParams={defaultExcelExportParams}
                    />
                    {(wbsData?.keywords &&
                        <><p>Keywords:</p>
                            <ul>
                                {(wbsData?.keywords && (
                                    wbsData?.keywords.map((keyword) => {
                                        return <li>
                                            <Link href={`/keywords/${keyword.keyword.id}`}>{keyword.keyword.name}</Link>
                                        </li>
                                    })
                                )
                                )}
                            </ul>
                        </>
                    )}
                </div>

                <div className={`row-details`}>
                    <h2>Task Detail</h2>
                    <Link href={`/projects/${taskData?.project?.id}/tasks/${taskData?.id}`}>{taskData?.name}</Link>
                    <p className='header'>Description</p>
                    <p>{taskData?.description}</p>
                    <p className='header'>Percent Complete</p>
                    <p>{taskData?.percent_complete}%</p>
                    <p className='header'>Project Controls</p>
                    <ul>
                        {taskData?.project_controls?.map(item => {
                            return <li><Link href={`/projects/${taskData?.project?.id}/controls/${item?.ProjectControl?.id}`}>{item?.Control?.name}</Link></li>
                        })}
                    </ul>
                    <p className='header'>Risks</p>
                    <ul>
                        {taskData?.risks?.map(item => {
                            return <li><Link href={`/projects/${taskData?.project?.id}/risks/${item?.Risk?.id}`}>{item?.Risk?.name}</Link></li>
                        })}
                    </ul>
                    <p className='header'>Audit Tests</p>
                    <ul>
                        {taskData?.audit_tests?.map(item => {
                            return <li><Link href={`/projects/${taskData?.project?.id}/audit_tests/${taskData?.AuditTest?.id}`}>{item?.AuditTest?.name}</Link></li>
                        })}
                    </ul>
                </div>
            </div>}
            {wbsView === 1 && <>
                <div>
                    <div className="gantt-container">
                        <GanttComponent></GanttComponent>
                    </div>
                </div>
            </>}
            {wbsView === 2 && <TaskCalendar />}

            <Dialog
                //fullScreen
                open={openCreateProjectTaskDialog}
                onClose={handleClose}
                aria-labelledby="create-data"
                aria-describedby="alert-dialog-create-data"
            >
                <DialogActions className="create-edit-dialog">
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
                <DialogTitle id="alert-dialog-title">
                    {`Create Task for Project`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <CreateEditProjectTask isOpen={isOpen} taskData={taskData} rowsData={rowData} wbs_id={wbsId} projId={projectData?.id} controlData={addControlData} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog
                //fullScreen
                open={openCreateProjectControlDialog}
                onClose={handleClose}
                aria-labelledby="create-data"
                aria-describedby="alert-dialog-create-data"
            >
                <DialogActions className="create-edit-dialog">
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
                <DialogTitle id="alert-dialog-title">
                    {`Associate Project Control with Task`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <CreateEditTaskProjectControl isOpen={isOpen} controlData={addControlData} projectId={projectData?.id} rowsData={rowData} taskData={taskData} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog
                //fullScreen
                open={openCreateRiskDialog}
                onClose={handleClose}
                aria-labelledby="create-data"
                aria-describedby="alert-dialog-create-data"
            >
                <DialogActions className="create-edit-dialog">
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
                <DialogTitle id="alert-dialog-title">
                    {`Associate Project Risk with Task`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <CreateEditTaskProjectRisk isOpen={isOpen} controlData={addControlData} projectId={projectData?.id} rowsData={rowData} taskData={taskData} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog
                //fullScreen
                open={openCreateAuditTestDialog}
                onClose={handleClose}
                aria-labelledby="create-data"
                aria-describedby="alert-dialog-create-data"
            >
                <DialogActions className="create-edit-dialog">
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
                <DialogTitle id="alert-dialog-title">
                    {`Associate Project Risk with Task`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <CreateEditTaskProjectAuditTest isOpen={isOpen} controlData={addControlData} projectId={projectData?.id} rowsData={rowData} taskData={taskData} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default WBSStudio;