import React, { useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CreateEdit from '../components/CreateEdit';
import CreateEditFrameworkVersion from '../components/CreateEditFrameworkVersion';
import CreateEditDocument from '../components/CreateEditDocument';
import SpinnerComponent from '../components/LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import List from '../components/lists/List';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
//import { useNavigate } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Loader from '../components/Loader';
import DocumentList from '../components/lists/DocumentList';
import CreateEditProjectData from '../components/CreateEditProjectData';
//import FormGroup from '@mui/material/FormGroup';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Switch from '@mui/material/Switch';
import '../css/Details.css';
/* This is a generic view for returning details on a data object */

export async function loadData (dataType, id) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/${dataType}/${id}`;
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })

  return response;
}

export async function downloadFileBlob(id,data) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/documents/download/${id}`;
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
    responseType: 'blob',
  }).then(response => {
    return response.data;
  }).then(blob => {
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = data.name;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }).catch(error => { return error.message; })
  return response;
}

export default function Details({ dataType, id, projectId, frameworkId, frameworkVersionId }) {

  const [data, setData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState(dataType);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataId, setDataId] = useState();

  const [frameworkName, setFrameworkName] = useState("");
  const [frameworkVersionName, setFrameworkVersionName] = useState("");

  useEffect(() => {
    if (localStorage.getItem("system_role") === "4" ||
      localStorage.getItem("system_role") === "1" ||
      localStorage.getItem("system_role") === "2" ||
      localStorage.getItem("system_role") === "3"
    ) {
      setIsSystemAdmin(true)
    }

  }, [setIsSystemAdmin])

  useEffect(() => {
    //console.log(data)
  }, [data])

  useEffect(() => {
    if ((data?.framework_versions && dataType === "controls") || (data?.framework_versions && dataType === "framework_versions")) {
      setFrameworkName(data.framework_versions[0]?.framework?.name)
      let fvname = data.framework_versions[0].version_prefix + " " + data.framework_versions[0].version_suffix
      setFrameworkVersionName(fvname)
    }
  }, [data.framework_versions, dataType])

  useEffect(() => {
  }, [isSystemAdmin])
  //const navigate = useNavigate();
  //axios error message
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
  }, [errMsg])

  //axios success message
  const [successMsg, setSuccessMsg] = useState("");
  useEffect(() => {
  }, [successMsg])

  const handleClose = () => {
    getData();
    setEditOpen(false);
  }

  const editData = () => {
    setEditOpen(true);
  }

  const isOpen = () => {
    handleClose();
  }

  const addControlData = (data) => {
    getData();
  }

  const getData = useCallback(async () => {
    if (dataType) {
      setLoading(true);
      const jsonData = await loadData(dataType, id);
      if (jsonData.status !== 200) {
        //handle the error
        setErrMsg(`${jsonData.status}`)
      }
      if (jsonData.status === 200) {
        setData(jsonData.data);
        setSuccessMsg(`Loaded data`)
        if (dataType === `documents`) {
          //set dataId for documents
          if (jsonData.data.assessments.length > 0) {
            setDataId(jsonData.data.assessments[0].assessment.id)
          }
          if (jsonData.data.audit_tests.length > 0) {
            setDataId(jsonData.data.audit_tests[0].audit_test.id)
          }
          if (jsonData.data.controls.length > 0) {
            setDataId(jsonData.data.controls[0].control.id)
          }
          if (jsonData.data.exceptions.length > 0) {
            setDataId(jsonData.data.exceptions[0].exception.id)
          }
          if (jsonData.data.framework_versions.length > 0) {
            setDataId(jsonData.data.framework_versions[0].framework_version.id)
          }
          if (jsonData.data.frameworks.length > 0) {
            setDataId(jsonData.data.frameworks[0].framework.id)
          }
          if (jsonData.data.project_controls.length > 0) {
            setDataId(jsonData.data.project_controls[0].project_control.id)
          }
          if (jsonData.data.project_evaluations.length > 0) {
            setDataId(jsonData.data.project_evaluations[0].project_evaluation.id)
          }
          if (jsonData.data.projects.length > 0) {
            setDataId(jsonData.data.projects[0].project.id)
          }
          if (jsonData.data.risks.length > 0) {
            setDataId(jsonData.data.risks[0].risk.id)
          }
          if (jsonData.data.tasks.length > 0) {
            setDataId(jsonData.data.tasks[0].task.id)
          }
          if (jsonData.data.wbs.length > 0) {
            setDataId(jsonData.data.wbs[0].wbs.id)
          }
        }

        setLoading(false);
      }
    }
  }, [dataType, id])

  const formatBreadCrumb = useCallback(() => {
    var str = String(dataType).split("_")
    str = str.join(" ").trim()
    setBreadcrumb(str);
  },[dataType])

  useEffect(() => {
    if (data.length === 0) {
      getData();
    }
    formatBreadCrumb();
  }, [data, getData, formatBreadCrumb]);

  useEffect(() => {
    //console.log('data', data)
  }, [data])

  if (loading) {
    return (
      <Loader />
    )
  }

  return (
    <>
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      {successMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="success">
          <Alert severity="success">{successMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <div className="details-view">
        {(!data && <SpinnerComponent />)}
        {(dataType !== "framework_versions" && dataType !== "project_evaluations" && dataType !== "controls" && dataType !== "projects" && dataType !== "documents" &&
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/system-administration">
              System Administration
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={`/${dataType}/list`}
            >
              {breadcrumb}
            </Link>
            <Typography color="text.primary">{data?.name}</Typography>
          </Breadcrumbs>
        )}
        {(dataType === "framework_versions" &&
          <Breadcrumbs><Link underline="hover" color="inherit" href="/system-administration">
            System Administration
          </Link>
            <Link
              underline="hover"
              color="inherit"
              href={`/frameworks/list`}
            >
              Frameworks
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={`/frameworks/${data?.framework?.id}`}
            >
              {data?.framework?.name}
            </Link>
            <Typography color="text.primary">{data ? data?.version_prefix + " " + data?.framework?.name + " " + data?.version_suffix : <></>}</Typography>
          </Breadcrumbs>)}

        {(dataType === "project_evaluations" &&
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/system-administration">
              System Administration
            </Link>
            <Link underline="hover" color="inherit" href={`/projects/list`}>
              Projects
            </Link>
            <Link underline="hover" color="inherit" href={`/projects/${data?.project?.id}`}>
              {data?.project?.name}
            </Link>
            <Link underline="hover" color="inherit" href={`/projects/${data?.project?.id}/project_evaluations`}>
              Evaluations
            </Link>
            <Typography color="text.primary">{data?.name}</Typography>
          </Breadcrumbs>
        )}
        {(dataType === "documents" &&
          <Breadcrumbs aria-label="breadcrumb">
            {(data?.audit_tests?.length > 0 && <>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.audit_tests[0].audit_test.project.id}`}>
                {data.audit_tests[0].audit_test.project.name}&nbsp;
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.audit_tests[0].audit_test.project.id}/documents`}>
                / Documents
              </Link>
            </>
            )}
            {(data?.assessments?.length > 0 && <>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.assessments[0].assessment.project.id}`}>
                {data.assessments[0].assessment.project.name}&nbsp;
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.assessments[0].assessment.project.id}/documents`}>
                / Documents
              </Link>
            </>
            )}
            {(data?.exceptions?.length > 0 && <>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.exceptions[0].exception.project.id}`}>
                {data.exceptions[0].exception.project.name}&nbsp;
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.exceptions[0].exception.project.id}/documents`}>
                / Documents
              </Link>
            </>
            )}
            {(data?.projects?.length > 0 && <>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.projects[0].project.id}`}>
                {data.projects[0].project.name}&nbsp;
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.projects[0].project.id}/documents`}>
                / Documents
              </Link>
            </>
            )}
            {(data?.project_evaluations?.length > 0 && <>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.project_evaluations[0].project_evaluation.project.id}`}>
                {data.project_evaluations[0].project_evaluation.project.name}&nbsp;
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.project_evaluations[0].project_evaluation.project.id}/documents`}>
                / Documents
              </Link>
            </>
            )}
            {(data?.risks?.length > 0 && <>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.risks[0].risk.project.id}`}>
                {data.risks[0].risk.project.name}&nbsp;
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.risks[0].risk.project.id}/documents`}>
                / Documents
              </Link>
            </>
            )}
            {(data?.tasks?.length > 0 && <>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.tasks[0].task.project.id}`}>
                {data.tasks[0].task.project.name}&nbsp;
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.tasks[0].task.project.id}/documents`}>
                / Documents
              </Link>
            </>
            )}
            {(data?.wbs?.length > 0 && <>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.wbs[0].wbs.project.id}`}>
                {data.wbs[0].wbs.project.name}&nbsp;
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.wbs[0].wbs.project.id}/documents`}>
                / Documents
              </Link>
            </>
            )}
            {(data?.project_controls?.length > 0 && <>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.project_controls[0].project_control.project.id}`}>
                {data.project_controls[0].project_control.project.name}&nbsp;
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={`/projects/${data.project_controls[0].project_control.project.id}/documents`}>
                / Documents
              </Link>
            </>
            )}
            {(data?.frameworks?.length > 0 && <>
              <Link
                underline="hover"
                color="inherit"
                href={`/frameworks/${data.frameworks[0].framework.id}`}>
                {data.frameworks[0].framework.name}&nbsp;
              </Link>
            </>
            )}
            {(data?.framework_versions?.length > 0 && <>
              <Link
                underline="hover"
                color="inherit"
                href={`/frameworks/${data.framework_versions[0].framework_version.framework.id}`}>
                {data.framework_versions[0].framework_version.framework.name}&nbsp;
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={`/frameworks/${data.framework_versions[0].framework_version.framework.id}/framework_versions/${data.framework_versions[0].framework_version.id}`}>
                / {data.framework_versions[0].framework_version.version_prefix + ' ' + data.framework_versions[0].framework_version.version_suffix}
              </Link>
            </>
            )}
            {(data?.controls?.length > 0 && <>
              <Link
                underline="hover"
                color="inherit"
                href={`/frameworks/${data.controls[0].control.framework_versions[0].framework.id}`}>
                {data.controls[0].control.framework_versions[0].framework.name}&nbsp;
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={`/frameworks/${data.controls[0].control.framework_versions[0].framework.id}/framework_versions/${data.controls[0].control.framework_versions[0].id}`}>
                / {data.controls[0].control.framework_versions[0].version_prefix + ' ' + data.controls[0].control.framework_versions[0].version_suffix}&nbsp;
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={`/frameworks/${data.controls[0].control.framework_versions[0].framework.id}/framework_versions/${data.controls[0].control.framework_versions[0].id}/controls/${data.controls[0].control.id}`}>
                / {data.controls[0].control.name}
              </Link>
            </>
            )}
            <Typography color="text.primary">{data?.title}</Typography>
          </Breadcrumbs>
        )}
        {(dataType === "controls" && !projectId &&
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/system-administration">
              System Administration
            </Link>

            <Link
                underline="hover"
                color="inherit"
                href={`/frameworks/${frameworkId}`}>
                {frameworkName}&nbsp;
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href={`/frameworks/${frameworkId}/framework_versions/${frameworkVersionId}`}>
                 {frameworkVersionName}&nbsp;
              </Link>
            <Typography color="text.primary">{data?.name}</Typography>
          </Breadcrumbs>
        )}
        {(dataType === "controls" && projectId &&
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/system-administration">
              System Administration
            </Link>
            <Link underline="hover" color="inherit" href={`/projects/list`}>
              Projects
            </Link>
            <Link underline="hover" color="inherit" href={`/projects/${projectId}`}>
              {data?.project?.name}
            </Link>
            <Typography color="text.primary">{data?.name}</Typography>
          </Breadcrumbs>
        )}
        {(dataType !== "projects" && dataType !== 'documents') && <h1>{data?.name ? data.name : <></>}</h1>}
        {(dataType === 'documents') && <h1>{data?.title ? data.title : <></>}</h1>}
        <div className='leftCol'>
          <div className="top-btn-group">
            {isSystemAdmin ? <Button variant="contained" className="edit-project-btn" onClick={editData}>Edit</Button> : <></>}
          </div>
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  {(dataType === "framework_versions" &&
                    <TableRow
                      key='prefix'
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left" className='title'>Version Prefix</TableCell>
                      <TableCell align="left">{data ? data?.version_prefix : <></>}</TableCell>
                    </TableRow>
                  )}
                  {(dataType === "framework_versions" &&
                    <TableRow
                      key='suffix'
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left" className='title'>Version Suffix</TableCell>
                      <TableCell align="left">{data ? data?.version_suffix : <></>}</TableCell>
                    </TableRow>
                  )}
                  {(dataType === "framework_versions" &&
                    <TableRow
                      key='guidance'
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left" className='title'>Guidance</TableCell>
                      <TableCell align="left">{data ? data?.guidance : <></>}</TableCell>
                    </TableRow>
                  )}
                  {(dataType === "framework_versions" &&
                    <TableRow
                      key='release'
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left" className='title'>Release Date</TableCell>
                      <TableCell align="left">{data ? data?.release_date : <></>}</TableCell>
                    </TableRow>
                  )}
                  {(dataType !== "framework_versions" && dataType !== "documents" && <><TableRow
                    key='name'
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left" className='title'>Name</TableCell>
                    <TableCell align="left">
                      {data.name}
                    </TableCell>

                  </TableRow>


                    <TableRow
                      key='project'
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left" className='title'>Description</TableCell>
                      <TableCell align="left">
                        {data?.description ? data.description : <></>}</TableCell>

                    </TableRow>
                  </>)}
                  <TableRow >
                    <TableCell align="left" className='title'>
                      Keywords
                    </TableCell>
                    <TableCell align="left">
                      <ul>
                        {(data?.keywords && (
                          data?.keywords.map((keyword) => {
                            return <li>
                              <Link href={`/keywords/${keyword.keyword.id}`}>{keyword.keyword.name}</Link>
                            </li>
                          })
                        )
                        )}
                      </ul>
                    </TableCell>
                  </TableRow>

                  {(dataType === "project_evaluations" &&
                    <TableRow
                      key='comments'
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left" className='title'>Comments</TableCell>
                      <TableCell align="left">
                        {data?.comments}
                      </TableCell>
                    </TableRow>
                  )}
                  {(dataType === "controls" &&
                  <TableRow
                  key='guidance'
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left" className='title'>Guidance</TableCell>
                    <TableCell align="left">
                      {/* // {JSON.stringify(data, NULL, 2)} */}
                      {data?.guidance}
                    </TableCell>
                    </TableRow>
                )}
                  {(dataType === "controls" &&
                    <TableRow
                      key='controls'
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left" className='title'>Framework Versions</TableCell>
                      <TableCell align="left">
                        <ul>
                          {data?.framework_versions?.map((version) => {
                            return (
                              <li>
                                <Link href={`/frameworks/${version?.framework?.id}/framework_versions/${version?.id}`}>{version?.version_prefix} {version?.framework?.name} {version?.version_suffix}</Link>
                              </li>
                            )
                          })}

                        </ul>
                      </TableCell>
                    </TableRow>
                  )} 
                  {(dataType === "projects" && <>
                    <TableRow
                      key='projects'
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left" className='title'>Project Admin</TableCell>
                      <TableCell align="left">
                        {data?.project_admin?.email}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      key='project_groups'
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left" className='title'>Project Group</TableCell>
                      <TableCell align="left">
                        <Link href={`/project_groups/${data?.project_group?.id}`}>{data?.project_group?.name}</Link>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      key='project_status'
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left" className='title'>Project Status</TableCell>
                      <TableCell align="left">
                        {data?.status}
                      </TableCell>
                    </TableRow>
                  </>
                  )}
                  {(dataType === "documents" &&
                    <>
                      <TableRow
                        key='documents'
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" className='title'>Version</TableCell>
                        <TableCell align="left">
                          {data?.version}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key='owner'
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" className='title'>Owner</TableCell>
                        <TableCell align="left">
                          <Link href={`/users/${data?.owner?.id}`}>{data?.owner?.email}</Link>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key='download'
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" className='title'>Download</TableCell>
                        <TableCell align="left">
                          <Button
                            variant="outlined"
                            onClick={() => downloadFileBlob(id, data)}
                          >
                            <FileDownloadIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                      {(data?.assessments?.length > 0 &&
                        <TableRow
                          key='download'
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left" className='title'>Assessment</TableCell>
                          <TableCell align="left">
                            <ul>
                              {data.assessments.map((assess) => {
                                return <p><Link href={`/projects/${assess?.assessment?.project?.id}/controls/${assess?.assessment?.project_control?.id}/assessments/${assess?.assessment?.id}`}>{assess?.assessment?.name}</Link></p>
                              })}
                            </ul>
                          </TableCell>
                        </TableRow>
                      )}
                      {(data?.audit_tests?.length > 0 &&
                        <TableRow
                          key='download'
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left" className='title'>Audit Test</TableCell>
                          <TableCell align="left">
                            {data?.audit_tests?.map((at) => {
                              return <p><Link href={`/projects/${at?.audit_test?.project?.id}/audit_tests/${at?.audit_test?.id}`}>{at?.audit_test?.name}</Link></p>
                            })}
                          </TableCell>
                        </TableRow>
                      )}
                      {(data?.controls?.length > 0 &&
                        <TableRow
                          key='download'
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left" className='title'>Control</TableCell>
                          <TableCell align="left">

                            {data?.controls?.map((control) => {
                              return <p><Link href={`/controls/${control?.control?.id}`}>{control?.control?.name}</Link></p>
                            })}

                          </TableCell>
                        </TableRow>
                      )}
                      {(data?.exceptions?.length > 0 &&
                        <TableRow
                          key='download'
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left" className='title'>Exceptions</TableCell>
                          <TableCell align="left">
                            <ul>
                              {data.exceptions.map((exception) => {
                                return <p><Link href={`/projects/${exception?.exception?.project?.id}/controls/${exception?.exception?.project_control?.id}/exceptions/${exception?.exception?.id}`}>{exception?.exception?.name}</Link></p>
                              })}
                            </ul>

                          </TableCell>
                        </TableRow>
                      )}
                      {(data?.frameworks?.length > 0 &&
                        <TableRow
                          key='download'
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left" className='title'>Framework</TableCell>
                          <TableCell align="left">

                            {data?.frameworks?.map((framework) => {
                              return <p><Link href={`/frameworks/${framework?.framework?.id}`}>{framework?.framework?.name}</Link></p>
                            })}

                          </TableCell>
                        </TableRow>
                      )}
                      {(data?.framework_versions?.length > 0 &&
                        <TableRow
                          key='download'
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left" className='title'>Framework Version</TableCell>
                          <TableCell align="left">

                            {data?.framework_versions?.map((framework_version) => {
                              return <p><Link href={`/framework_versions/${framework_version?.framework_version?.id}`}>{framework_version?.framework_version?.name}</Link></p>
                            })}

                          </TableCell>
                        </TableRow>
                      )}
                      {(data?.projects?.length > 0 &&
                        <TableRow
                          key='download'
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left" className='title'>Project</TableCell>
                          <TableCell align="left">
                            {data?.projects?.map((project) => {
                              return <p><Link href={`/projects/${project?.project?.id}`}>{project?.project?.name}</Link></p>
                            })}
                          </TableCell>
                        </TableRow>
                      )}
                      {(data?.project_evaluations?.length > 0 &&
                        <TableRow
                          key='download'
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left" className='title'>Project Evaluations</TableCell>
                          <TableCell align="left">
                            {data?.project_evaluations?.map((project_evaluation) => {
                              return <p><Link href={`/projects/${project_evaluation?.project_evaluation?.project?.id}/project_evaluations/${project_evaluation?.project_evaluation?.id}`}>{project_evaluation?.project_evaluation?.name}</Link></p>
                            })}
                          </TableCell>
                        </TableRow>
                      )}
                      {(data?.risks?.length > 0 &&
                        <TableRow
                          key='download'
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left" className='title'>Risks</TableCell>
                          <TableCell align="left">
                            {data?.risks?.map((risk) => {
                              return <p><Link href={`/projects/${risk?.risk?.project?.id}/risks/${risk?.risk?.id}`}>{risk?.risk?.name}</Link></p>
                            })}
                          </TableCell>
                        </TableRow>
                      )}
                      {(data?.tasks?.length > 0 &&
                        <TableRow
                          key='download'
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left" className='title'>Tasks</TableCell>
                          <TableCell align="left">
                            {data?.tasks?.map((task) => {
                              return <p><Link href={`/projects/${task?.task?.project?.id}/tasks/${task?.task?.id}`}>{task?.task?.name}</Link></p>
                            })}
                          </TableCell>
                        </TableRow>
                      )}
                      {(data?.wbs?.length > 0 &&
                        <TableRow
                          key='download'
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left" className='title'>WBS</TableCell>
                          <TableCell align="left">
                            {data?.wbs?.map((wbs) => {
                              return <p><Link href={`/projects/${wbs?.wbs?.project?.id}/wbs/${wbs?.wbs?.id}`}>{wbs?.wbs?.name}</Link></p>
                            })}
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

          </>
        </div>
        {(dataType !== "documents" && dataType !== "projects" &&
          <div className='rightCol'>
            <h2>Documents</h2>
            <DocumentList documentListData={data?.documents} dataType={dataType} dataId={data?.id} />
          </div>)}
        {(dataType === "project_groups" &&
          <><h2>Projects</h2><List dataType="projects" projectGroupId={window.location.pathname.split("/")[2]} /></>
        )}
        <Dialog
          open={editOpen}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          className={`dialog edit`}
        >
          <DialogActions className="create-edit-dialog">
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
          <DialogTitle>Edit {data?.name}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {(dataType === "framework_versions" &&
                <CreateEditFrameworkVersion
                  dataType={dataType}
                  editDataObj={data}
                  controlFrameworkId={data?.framework?.id}
                  isOpen={isOpen}
                />)}
              {(dataType === "documents" &&
                <CreateEditDocument
                  dataId={dataId}
                  dataType={`projects`}
                  editDataObj={data}
                  isOpen={isOpen}
                  controlData={addControlData}
                />)}
              {(dataType === "project_evaluations" &&
                <CreateEditProjectData
                  dataType={dataType}
                  editDataObj={data}
                  controlFrameworkId={data?.framework?.id}
                  isOpen={isOpen}
                  controlData={addControlData}
                />)}
              {(dataType !== "framework_versions" && dataType !== "documents" && dataType !== "project_evaluations" &&
                <CreateEdit dataType={dataType} editDataObj={data} controlFrameworkId={data?.framework?.id} isOpen={isOpen} />)}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  )
}